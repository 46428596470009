var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper pointer"},[_c('div',{staticClass:"col-12 pa-2 col-block redlist pointer",class:{
    greenlist: _vm.status == 'Won',
    blacklist: _vm.status == 'Pulled'
  },on:{"click":function($event){return _vm.startBidChat(_vm.bid)}}},[_c('div',{staticClass:"username"},[_vm._v(" "+_vm._s(_vm.bid.task.code)+" "+_vm._s(": ")+" "+_vm._s(_vm.bid.task.topic)+" "),(_vm.bid.unread_message)?_c('v-icon',{staticClass:"ml-4 green--text",attrs:{"small":""}},[_vm._v(" mdi-message ")]):_vm._e()],1),_c('span',{staticClass:"bold-2 mr-1 align-center d-flex justify-kjend"},[_c('span',{staticClass:"username"},[_vm._v(" Task by: "+_vm._s(_vm.bid.task.broker.user.username + ' ')+" ")]),(_vm.bid.task.pages)?_c('span',{staticClass:"bold-2 ml-1  d-flex justify-n"},[_vm._v(" "+_vm._s("( " + _vm.bid.task.pages + " " + "pages ")+" "),_c('v-icon',{staticClass:"mx-1 green--text",attrs:{"x-small":""}},[_vm._v(" mdi-at ")]),_vm._v(" "+_vm._s(_vm.bid.task.page_cost + " )")+" ")],1):_vm._e()]),_c('span',{staticClass:"d-flex my-0 mr-1 justify-"},[_vm._v(" "+_vm._s(_vm.bid.task.unit + " " + _vm.bid.task.type)+" "),_c('v-icon',{staticClass:"mx-1 green--text",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(_vm._f("diffForHumans")(_vm.bid.task.expiry_time))+" "+_vm._s(' to go')+" ")],1),_c('span',{class:{
      'red--text': _vm.status == 'Unassigned' || _vm.status == 'Rejected',
      'green--text': _vm.status == 'Won',
      'black--text': _vm.status == 'Pulled' || _vm.status == 'Lost'
    }},[_vm._v(" "+_vm._s("Bid " + _vm.status)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }